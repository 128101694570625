import React from "react";
import { Toaster } from "react-hot-toast";
import {BrowserRouter as Router, Routes, Route } from "react-router-dom";

import WheelContent from "./components/WheelContent";

const App = () => {
  return (
  <Router>
    <div>
      <Toaster
        position="bottom-center"
        toastOptions={{
          duration: 4000,
        }}
      />
      <Routes>
          <Route path="/wheel" element={<WheelContent />} />
      </Routes>
    </div>
    </Router>
  );
};

export default App;
